import { Buffer } from "buffer";

import { createSlice, current } from "@reduxjs/toolkit";

import { ROLES } from "../../../components/helpers/constants";
import {
  encryptPayload,
  getObjLength,
  reactPixelEvent,
  user_signup_details,
} from "../../../components/helpers/utils";

const intialUserSignupState = {
  initialActiveStep: 0,
  completedStep: null,
  userEmail: "",
  userSignupDetails: {},
  stateList: [],
  createAccResponse: {},
  otpVerificationResponse: {},
  emailOTPVerificationResponse: {},
  resumeProcessResponse: {},
  newOTP: {},
  newEmailOTP: {},
  statesCodeList: {},
  userPropertyData: {},
  getPropertyData: {},
  selectedProperty: {},
  propertyQuestion: {},
  propertyIds: [],
  properties: [],
  propertyLoader: false,
  propertyVerified: {},
  skipVerifyAnswers: {},
  verifiedPropertiesList: {},
  verifiedProperties: 0,
  faceRegistered: {},
  signatureRegistered: {},
  mailingAddress: {},
  mailingAddressLoader: false,
  creditCardInfo: {},
  subscriptionPlans: {},
  paymentData: {},
  finalAmountPayload: {},
  totalPaymentDetails: {},
  totalPayment: {},
  isPaymentDone: {},
  createAcc: false,
  step1Mobile: false,
  step1Email: false,
  step2: false,
  step3: false,
  step4: false,
  beneficiaryList: [],
  beneficiaryError: "",
  beneficiaryLoader: false,
  sendMailDetails: {},
  sendMailLoader: false,
  paymnetLoader: false,
  resumeProcessLoader: false,
  resumeProcessData: {},
  userSecurityPin: {},
  signupLoader: false,
  statesLoader: false,
  errorFlagCount: 0,
  countyList: {},
  adminCreatedUser: false,
  signBase64: "",
  propertyListLoader: false,
  deletedPropertyResponse: {},
  showSecurityInfoScreen: false,
  resendPhone_OTP: false,
  resendEmail_OTP: false,
  userCredentials: {},
  userSignInDetails: {},
  userLoginLoader: false,
  getInvitationDetails: {},
  checkReCAPTCHADetails: {},
  checkReCAPTCHALoader: false,
  isFromIosDevice: false,
  totalPaymentLoader: false,
  getTotalAmountLoader: false,
};

const signupUser = createSlice({
  name: "userSignupDetails",
  initialState: intialUserSignupState,
  reducers: {
    resetSignupState: () => intialUserSignupState,
    getState(state, action) {
      console.log("get state payload state: ");
    },
    fetchSateData(state, action) {
      console.log("In state slice...", action.payload);
      if (action.payload.success) {
        state.stateList = action.payload.data;
      } else {
        state.stateList = [];
      }
      const errCount = localStorage.getItem("errorCount");
      state.errorFlagCount = errCount ? parseInt(errCount) : 0;
    },
    setErrorCount(state, action) {
      console.log("setErrorCount", action.payload);
      state.errorFlagCount = action.payload;
    },

    userSignupData(state, action) {
      console.log("In slice...", action.payload);
      state.signupLoader = true;
      state.resendPhone_OTP = false;
      state.resendEmail_OTP = false;

      // Encode a string
      let password = action.payload?.data?.password;
      const encodedPassword = password
        ? Buffer.from(password).toString("base64")
        : null;
      console.log("Encoded Password:", encodedPassword);

      // localStorage.setItem("errorCount", 0);

      // removing password and confirm password from object
      let updatedObject = {};

      for (let key in action.payload?.data) {
        if (key !== "password" && key !== "confirm_password") {
          console.log("keyss=>", key);
          updatedObject[key] = action.payload?.data[key];
        }
      }
      console.log("updatedObject==>", updatedObject);
      let data = {
        data: {
          ...updatedObject,
          // password: encodedPassword
        },
      };
      state.userSignupDetails = data;
      sessionStorage.setItem(
        "userSignupDetails",
        JSON.stringify({
          data: { ...updatedObject, password: encodedPassword },
        })
      );
      localStorage.setItem("userSignupDetails", JSON.stringify(data.data));
      state.userEmail = action.payload?.data?.email;
    },
    userAddedByAdmin(state, action) {
      console.log("get state payload state: ", action.payload);
      state.adminCreatedUser = action.payload;
    },
    fetchSuccess(state, action) {
      const errCount = localStorage.getItem("errorCount");
      console.log(parseInt(errCount), "Payload sucewss==>", action.payload);
      state.createAccResponse = action.payload;
      state.createAcc = action.payload.success;
      state.user_id = action.payload.data?.user_id;
      state.signupLoader = false;
      localStorage.setItem(
        "user_Id",
        JSON.stringify(action.payload?.data?.user_id)
      );
      if (action.payload?.success) {
        localStorage.setItem("currentStep", "0");
        localStorage.setItem("emailScreen", "0");
        localStorage.setItem("errorCount", 0);
        localStorage.removeItem("host_code");
        state.errorFlagCount = 0;
        if (state.adminCreatedUser) {
          reactPixelEvent("lead2", current(state.userSignupDetails?.data));
        } else {
          reactPixelEvent("lead2", current(state.userSignupDetails?.data));
        }
      } else {
        localStorage.setItem(
          "errorCount",
          (errCount ? parseInt(errCount) : 0) + 1
        );
        state.errorFlagCount = (errCount ? parseInt(errCount) : 0) + 1;
      }
    },
    resetCreateAccResponse(state, action) {
      state.createAccResponse = {};
    },
    userVerifyOTP(state, action) {},
    fetchOtpData(state, action) {
      const errCount = localStorage.getItem("errorCount");
      console.log("Payload sucewss otp data fetched==>", action.payload);
      state.otpVerificationResponse = action.payload;
      state.step1Mobile = action.payload.success;
      if (action.payload?.success) {
        localStorage.setItem("currentStep", "0");
        localStorage.setItem("emailScreen", "1");
        localStorage.setItem("errorCount", 0);
        state.errorFlagCount = 0;
      } else {
        localStorage.removeItem("currentStep");
        localStorage.setItem("emailScreen", "0");
        localStorage.setItem(
          "errorCount",
          (errCount ? parseInt(errCount) : 0) + 1
        );
        state.errorFlagCount = (errCount ? parseInt(errCount) : 0) + 1;
      }
    },
    resedOTP(state, action) {},
    fetchNewOtpData(state, action) {
      const errCount = localStorage.getItem("errorCount");
      console.log("Payload sucewss new otp data fetched==>", action.payload);
      state.newOTP = action.payload;
      state.step1Mobile = false;
      state.otpVerificationResponse = {};
      // state.step1 = action.payload.success;
      if (action.payload?.success) {
        localStorage.setItem("errorCount", 0);
        state.errorFlagCount = 0;
      } else {
        localStorage.setItem(
          "errorCount",
          (errCount ? parseInt(errCount) : 0) + 1
        );
        state.errorFlagCount = (errCount ? parseInt(errCount) : 0) + 1;
      }
    },
    userVerifyResumeEmail(state, action) {
      state.resumeProcessLoader = true;
    },
    fetchResumeData(state, action) {
      const errCount = localStorage.getItem("errorCount");
      console.log("Payload sucewss otp data fetched==>", action.payload);
      state.resumeProcessLoader = false;
      state.showSecurityInfoScreen = false;
      state.resumeProcessResponse = action.payload;
      if (action.payload?.success) {
        localStorage.setItem(
          "currentStep",
          JSON.stringify(action.payload?.data?.json_last_step?.step)
        );
        localStorage.setItem(
          "user_Id",
          JSON.stringify(action.payload?.data?.id)
        );
        localStorage.setItem(
          "userSignupDetails",
          JSON.stringify(action.payload?.data?.user_signup_details)
        );
        localStorage.setItem(
          "userSignupAddress",
          JSON.stringify(action.payload?.data?.address)
        );
        // localStorage.setItem("searched_Add_popup", true);
        if (action.payload?.data?.promo_code) {
          localStorage.setItem("promoCode", action.payload?.data?.promo_code);
        }
        localStorage.setItem(
          "referralCode",
          action.payload?.data?.referral_code
        );

        if (action.payload?.data?.json_last_step?.step === 0) {
          if (action.payload?.data?.json_last_step?.step_key === "SEND_OTP") {
            state.resendPhone_OTP = true;
          }
          if (
            action.payload?.data?.json_last_step?.step_key ===
            "VERIFY_PHONE_OTP"
          ) {
            localStorage.setItem("emailScreen", 1);
            state.resendEmail_OTP = true;
          }

          if (getObjLength(action.payload?.data?.searched_address)) {
            localStorage.setItem("searched_Add_popup", true);
            localStorage.setItem(
              "searched_Add",
              action.payload?.data?.searched_address?.full_address
            );
            localStorage.setItem(
              "param",
              JSON.stringify(action.payload?.data?.searched_address?.param)
            );
            localStorage.setItem(
              "fixParams",
              JSON.stringify(
                action.payload?.data?.searched_address?.fixed_params
              )
            );
          }
        } else {
          state.resendEmail_OTP = false;
          state.resendPhone_OTP = false;
        }

        if (
          action.payload?.data?.json_last_step?.step === 1 ||
          action.payload?.data?.json_last_step?.step === 2
        ) {
          localStorage.setItem("searched_Add_popup", true);
          if (getObjLength(action.payload?.data?.searched_address)) {
            localStorage.setItem(
              "searched_Add",
              action.payload?.data?.searched_address?.full_address
            );
            localStorage.setItem(
              "param",
              JSON.stringify(action.payload?.data?.searched_address?.param)
            );
            localStorage.setItem(
              "fixParams",
              JSON.stringify(
                action.payload?.data?.searched_address?.fixed_params
              )
            );
          }
        }

        if (action.payload?.data?.json_last_step?.step === 2) {
          localStorage.setItem("isOwnerVerified", 1);
        }

        if (
          action.payload?.data?.json_last_step?.step_key === "VERIFY_ANSWERS" ||
          action.payload?.data?.json_last_step?.step_key ===
            "SKIP_VERIFY_ANSWERS"
        ) {
          localStorage.setItem("isOwnerVerified", 1);
          localStorage.setItem("currentStep", 2);
          localStorage.setItem("skip", 1);
        }

        if (action.payload?.data?.json_last_step?.step_key === "SECURITY_PIN") {
          localStorage.setItem("currentStep", 5);
          localStorage.setItem("skip", 1);
        }

        if (
          action.payload?.data?.json_last_step?.step_key ===
          "REGISTER_SIGNATURE"
        ) {
          localStorage.setItem("skip", 1);
          localStorage.setItem("currentStep", 7);
        }

        if (
          action.payload?.data?.json_last_step?.step_key ===
          "SAVE_BENEFICIARIES"
        ) {
          localStorage.setItem("currentStep", 7);
          localStorage.setItem("completed", 1);
        }

        if (getObjLength(action.payload?.data?.token_details)) {
          console.log("getObjLength==", action.payload?.data?.token_details);
          localStorage.setItem(
            "setLoginUserToken",
            action.payload?.data?.token_details?.AccessToken
          );
        }
      }

      //expire the cookie
      document.cookie =
        "iosValue =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
    userVerifyEmailOTP(state, action) {
      state.resumeProcessLoader = true;
    },
    fetchEmailOtpData(state, action) {
      const errCount = localStorage.getItem("errorCount");
      console.log("Payload sucewss otp data fetched==>", action.payload);
      state.resumeProcessLoader = false;
      state.emailOTPVerificationResponse = action.payload;
      state.step1Email = action.payload.success;
      if (action.payload?.success) {
        // localStorage.removeItem("emailScreen");
        localStorage.setItem("currentStep", "1");
        localStorage.setItem("errorCount", 0);
        state.errorFlagCount = 0;
      } else {
        localStorage.setItem("currentStep", "0");
        localStorage.setItem(
          "errorCount",
          (errCount ? parseInt(errCount) : 0) + 1
        );
        state.errorFlagCount = (errCount ? parseInt(errCount) : 0) + 1;
      }
    },
    resedEmailOTP(state, action) {},
    fetchNewEmailOtpData(state, action) {
      const errCount = localStorage.getItem("errorCount");
      console.log("Payload sucewss new otp data fetched==>", action.payload);
      state.newEmailOTP = action.payload;
      state.step1Email = false;
      state.emailOTPVerificationResponse = {};
      // state.step1 = action.payload.success;
      if (action.payload?.success) {
        localStorage.setItem("errorCount", 0);
        state.errorFlagCount = 0;
      } else {
        localStorage.setItem(
          "errorCount",
          (errCount ? parseInt(errCount) : 0) + 1
        );
        state.errorFlagCount = (errCount ? parseInt(errCount) : 0) + 1;
      }
    },
    clearResedOTP(state, action) {
      state.newOTP = {};
      state.newEmailOTP = {};
      state.otpVerificationResponse = {};
      state.emailOTPVerificationResponse = {};
      state.resendPhone_OTP = false;
      state.resendEmail_OTP = false;
    },
    clearData(state, action) {
      state.initialActiveStep = 0;
      state.userSignupDetails = {};
      // state.stateList = [];
      state.createAccResponse = {};
      state.otpVerificationResponse = {};
      state.newOTP = {};
      state.emailOTPVerificationResponse = {};
      state.newEmailOTP = {};
      // state.statesCodeList = {};
      state.statesLoader = false;
      state.userPropertyData = {};
      state.getPropertyData = {};
      state.selectedProperty = {};
      state.propertyQuestion = {};
      state.propertyIds = [];
      state.properties = [];
      state.propertyVerified = {};
      state.verifiedPropertiesList = {};
      state.verifiedProperties = 0;
      state.faceRegistered = {};
      state.signatureRegistered = {};
      state.creditCardInfo = {};
      state.mailingAddress = {};
      // state.subscriptionPlans = {};
      state.paymentData = {};
      state.totalPaymentDetails = {};
      state.createAcc = false;
      state.step1Mobile = false;
      state.step1Email = false;
      state.step2 = false;
      state.step3 = false;
      state.step4 = false;
      state.beneficiaryList = [];
      state.beneficiaryError = "";
      state.beneficiaryLoader = false;
      state.sendMailDetails = {};
      // state.sendMailLoader = false;
      state.propertyLoader = false;
      state.userSecurityPin = {};
      state.totalPaymentLoader = false;
    },
    getStateCode(state, action) {
      state.statesLoader = true;
      state.countyList = {};
      state.resendPhone_OTP = false;
      state.resendEmail_OTP = false;
    },
    fetchSateCodeData(state, action) {
      console.log("In statecode slice...", action.payload);
      if (action.payload.success) {
        state.statesCodeList = action.payload.data;
      } else {
        state.statesCodeList = {};
      }
      state.statesLoader = false;
    },
    getCountyList(state, action) {
      state.statesLoader = true;
    },
    fetchCountyData(state, action) {
      console.log("In statecode slice...", action.payload);
      if (action.payload.success) {
        state.countyList = action.payload;
        state.statesLoader = false;
      } else {
        state.countyList = {};
      }
    },
    getUserProperty(state, action) {
      localStorage.removeItem("emailScreen");
      console.log("In property slice...", action.payload);
      state.skipVerifyAnswers = {};
      state.userPropertyData = action.payload;
      state.propertyLoader = true;
      state.propertyQuestion = {};
      state.verifiedPropertiesList = {};
      state.propertyVerified = {};
    },
    fetchUserPropertyData(state, action) {
      console.log("In Property slice...", action.payload);
      state.getPropertyData = action.payload;
      state.verifiedPropertiesList = {};
      state.propertyVerified = {};
      state.propertyLoader = false;
      const errCount = localStorage.getItem("errorCount");
      if (action.payload?.success) {
        // localStorage.setItem("errorCount", 0);
        // state.errorFlagCount = 0;
      } else {
        localStorage.setItem(
          "errorCount",
          (errCount ? parseInt(errCount) : 0) + 1
        );
        state.errorFlagCount = (errCount ? parseInt(errCount) : 0) + 1;
      }
    },
    selectedUserPropertyData(state, action) {
      console.log("In selected Property slice...", action.payload);
      state.selectedProperty = action.payload;
    },

    generateQuestions(state, action) {
      state.skipVerifyAnswers = {};
      state.propertyLoader = true;
      state.propertyListLoader = true;
      console.log("In generateQuestions slice...", action.payload);
      console.log(
        getObjLength(current(state?.verifiedPropertiesList)),
        "verifiedPropertiesList...",
        current(state?.verifiedPropertiesList)
      );
      if (
        getObjLength(current(state?.verifiedPropertiesList)) &&
        current(state?.verifiedPropertiesList?.data)?.length > 0
      ) {
        state.verifiedProperties = action.payload?.data?.length;
        localStorage.setItem("currentStep", "2");
        localStorage.setItem("isOwnerVerified", "1");
        localStorage.setItem("OwnerVerifiedScreen", "1");
      }
      // else {
      //   localStorage.setItem("currentStep", "1");
      //   localStorage.setItem("isOwnerVerified", "0");
      // }
    },
    fetchGeneratedQuestionsData(state, action) {
      console.log("user_signup_details=>", user_signup_details?.email);
      console.log("In statecode slice...", action.payload);
      console.log("propertyIds==>", state.propertyIds);
      state.propertyQuestion = action.payload;
      // if (action.payload?.success) {
      //   let propertiesID = action.payload?.data?.property_id;
      //   state.propertyIds = [...state.propertyIds, propertiesID];
      //   localStorage.setItem("ptIds", [...state.propertyIds, propertiesID]);
      // }
      state.propertyLoader = false;
      state.propertyListLoader = false;
      const errCount = localStorage.getItem("errorCount");
      if (action.payload?.success) {
        state.verifiedPropertiesList = {};
        localStorage.setItem("errorCount", 0);
        state.errorFlagCount = 0;
      } else {
        localStorage.setItem(
          "errorCount",
          (errCount ? parseInt(errCount) : 0) + 1
        );
        state.errorFlagCount = (errCount ? parseInt(errCount) : 0) + 1;
      }
    },
    showSecurityInfoScreen(state, action) {
      console.log("showSecurityInfoScreen...", action.payload);
      state.showSecurityInfoScreen = action.payload;
    },
    setPropertiesId(state, action) {
      console.log("propertyIds...", action.payload);
      state.propertyIds = action.payload?.propertyIds;
      state.properties = action.payload?.properties;
      // localStorage.setItem("ptIds", action.payload);
    },
    verifyAnswers(state, action) {
      console.log("In verify answer slice...", action.payload);
      state.signupLoader = true;
    },
    fetchVerifedAnswersData(state, action) {
      const errCount = localStorage.getItem("errorCount");
      console.log("In verify answer slice...", action.payload);
      state.propertyVerified = action.payload;

      if (action.payload?.success) {
        localStorage.setItem("currentStep", "2");
        localStorage.setItem("isOwnerVerified", "1");
        localStorage.setItem("OwnerVerifiedScreen", "1");
        localStorage.removeItem("param");
        localStorage.removeItem("fixParams");
        localStorage.removeItem("searched_Add");
        localStorage.removeItem("searched_Add_popup");
        localStorage.setItem("errorCount", 0);
        state.errorFlagCount = 0;
      } else {
        if ((errCount ? parseInt(errCount) : 0) + 1 >= 3) {
          localStorage.setItem("currentStep", "1");
          localStorage.setItem("isOwnerVerified", "0");
        }
        localStorage.setItem(
          "errorCount",
          (errCount ? parseInt(errCount) : 0) + 1
        );
        state.errorFlagCount = (errCount ? parseInt(errCount) : 0) + 1;
      }
      state.signupLoader = false;
    },
    skipVerifyAnswers(state, action) {
      console.log("In verify answer slice...", action.payload);
      state.signupLoader = true;
    },
    clearSkipVerifyAnswers(state, action) {
      console.log("In verify answer slice...", action.payload);
      state.skipVerifyAnswers = {};
    },
    fetchSkipVerifyAnswers(state, action) {
      const errCount = localStorage.getItem("errorCount");
      console.log("In verify answer slice...", action.payload);
      state.skipVerifyAnswers = action.payload;

      if (action.payload?.success) {
        localStorage.setItem("currentStep", "2");
        localStorage.setItem("isOwnerVerified", "1");
        localStorage.setItem("OwnerVerifiedScreen", "1");
        localStorage.removeItem("param");
        localStorage.removeItem("fixParams");
        localStorage.removeItem("searched_Add");
        localStorage.removeItem("searched_Add_popup");
        localStorage.setItem("errorCount", 0);
        localStorage.removeItem("property_search_data");
        localStorage.removeItem("user_details");
        localStorage.removeItem("host_code");
        state.errorFlagCount = 0;
      } else {
        if ((errCount ? parseInt(errCount) : 0) + 1 >= 3) {
          localStorage.setItem("currentStep", "1");
          localStorage.setItem("isOwnerVerified", "0");
        }
        localStorage.setItem(
          "errorCount",
          (errCount ? parseInt(errCount) : 0) + 1
        );
        state.errorFlagCount = (errCount ? parseInt(errCount) : 0) + 1;
      }
      state.signupLoader = false;

      console.log("In verify answer slice...", action.payload);
    },
    clearPropertyVerified(state, action) {
      console.log("clear data payloda==>", action.payload);
      state.propertyVerified = {};
      state.verifiedPropertiesList = {};
      if (action.payload === "propertyListScreen") {
        state.propertyQuestion = {};
      }
    },
    getPropertiesList(state, action) {
      state.signupLoader = true;
      state.propertyListLoader = true;
      console.log("In PropertiesList slice...", action.payload);
    },
    deleteProperties(state, action) {
      state.propertyListLoader = true;
      console.log("In PropertiesList slice...", action.payload);
    },
    deletePropertiesResponse(state, action) {
      console.log("In PropertiesList slice...", action.payload);
      state.propertyListLoader = false;
      state.deletedPropertyResponse = action.payload;
    },
    clearDeletePropertiesResponse(state, action) {
      console.log("In PropertiesList slice...", action.payload);
      // state.propertyListLoader = false;
      state.deletedPropertyResponse = {};
    },
    fetchgetPropertiesListData(state, action) {
      console.log("In PropertiesList slice...", action.payload);
      state.signupLoader = false;
      state.propertyListLoader = false;
      state.verifiedPropertiesList = action.payload;
      if (action.payload?.success && action.payload?.data?.length > 0) {
        console.log("In PropertiesList more then 0");
        state.verifiedProperties = action.payload?.data?.length;
        localStorage.setItem("skip", "1");
      } else {
        localStorage.setItem("skip", "0");
      }
      const errCount = localStorage.getItem("errorCount");
      if (action.payload?.success) {
        localStorage.setItem("errorCount", 0);
        state.errorFlagCount = 0;
        const propertiesID = action.payload?.data?.map((data, key) => {
          return {
            property_id: data?.property_id,
            property_address: data?.address,
          };
        });
        const propetiesDetails = {
          propetiesDetails: propertiesID,
          email: user_signup_details()?.email,
        };
        localStorage.setItem(
          "propetiesDetails",
          JSON.stringify(propetiesDetails)
        );
        reactPixelEvent("Properties Added", propetiesDetails);
        console.log("pId==>", propetiesDetails);
      } else {
        localStorage.setItem(
          "errorCount",
          (errCount ? parseInt(errCount) : 0) + 1
        );
        state.errorFlagCount = (errCount ? parseInt(errCount) : 0) + 1;
      }
    },

    addNewProperty(state, action) {
      console.log("New property slice..", action.payload);
      state.getPropertyData = {};
      state.selectedProperty = {};
      state.propertyQuestion = {};
      localStorage.setItem("errorCount", 0);
      state.errorFlagCount = 0;
      state.totalPaymentDetails = {};
    },
    clearPropertyQuestion(state, action) {
      state.propertyQuestion = {};
      localStorage.setItem("errorCount", 0);
    },
    skipRegisterFaceStep(state, action) {
      console.log("skip register-face==>>", action.payload);
    },
    skipRegisterFaceStepData(state, action) {
      console.log("skip register-face response==>>", action.payload);
    },
    getRegisterFace(state, action) {
      console.log("register-face==>>", action.payload);
    },
    fetchRegisterFace(state, action) {
      console.log("fetchRegisterFace slice..", action.payload);
      state.faceRegistered = action.payload;
      const errCount = localStorage.getItem("errorCount");
      if (action.payload?.success) {
        localStorage.setItem("currentStep", "6");
        localStorage.removeItem("skip");
        localStorage.setItem("errorCount", 0);
        state.errorFlagCount = 0;
      } else {
        localStorage.setItem("currentStep", "5");
        localStorage.setItem("skip", "1");
        localStorage.setItem(
          "errorCount",
          (errCount ? parseInt(errCount) : 0) + 1
        );
        state.errorFlagCount = (errCount ? parseInt(errCount) : 0) + 1;
      }
    },
    getRegisterSignature(state, action) {
      console.log("register-Signature==>>", action.payload);
    },
    fetchRegisterSignature(state, action) {
      console.log("fetchRegisterSignature slice..", action.payload);
      state.signatureRegistered = action.payload;
      localStorage.setItem("completed", "0");
      const errCount = localStorage.getItem("errorCount");
      if (action.payload?.success) {
        localStorage.setItem("currentStep", "7");
        localStorage.setItem("skip", "1");
        localStorage.setItem("errorCount", 0);
        state.errorFlagCount = 0;
      } else {
        localStorage.setItem("currentStep", "6");
        localStorage.setItem("skip", "0");
        localStorage.setItem(
          "errorCount",
          (errCount ? parseInt(errCount) : 0) + 1
        );
        state.errorFlagCount = (errCount ? parseInt(errCount) : 0) + 1;
      }
    },
    getMailingAddress(state, action) {
      // state.signupLoader = true;
      state.skipVerifyAnswers = {};
      state.mailingAddressLoader = true;
      console.log("MailingAddress==>>", action.payload);
      // localStorage.removeItem("isOwnerVerified");
      localStorage.removeItem("skip");
    },
    fetchMailingAddress(state, action) {
      // state.signupLoader = false;
      state.mailingAddressLoader = false;
      console.log("MailingAddress slice..", action.payload);
      state.mailingAddress = action.payload;
      localStorage.setItem("OwnerVerifiedScreen", "1");
      if (action.payload?.success) {
        localStorage.setItem("currentStep", "2"); //previous step 3
        localStorage.setItem("subscriptionScreen", "0");
        localStorage.setItem("isOwnerVerified", "1");
      } else {
        localStorage.setItem("currentStep", "2");
        localStorage.setItem("isOwnerVerified", "1");
      }
    },
    saveCreditCardInfo(state, action) {
      console.log("saveCreditCardInfo==>>", action.payload);
    },
    fetchCreditCardInfo(state, action) {
      const errCount = localStorage.getItem("errorCount");
      console.log("fetchCreditCardInfo slice..", action.payload);
      state.creditCardInfo = action.payload;
      if (action.payload?.success) {
        localStorage.setItem("subscriptionScreen", "1");
        // localStorage.setItem("errorCount", 0);
        // state.errorFlagCount = 0;
      } else {
        localStorage.setItem("subscriptionScreen", "0");
        localStorage.setItem(
          "errorCount",
          (errCount ? parseInt(errCount) : 0) + 1
        );
        state.errorFlagCount = (errCount ? parseInt(errCount) : 0) + 1;
      }
    },
    getSubscriptionPlan(state, action) {
      console.log("SubscriptionPlan==>>", action.payload);
      state.statesLoader = true;
    },
    fetchSubscriptionPlan(state, action) {
      console.log("SubscriptionPlan ==>", action.payload);
      state.subscriptionPlans = action.payload;
      state.statesLoader = false;
    },
    getPayment(state, action) {
      console.log("SubscriptionPlan==>>", action.payload);
      state.paymnetLoader = true;
      state.paymentData = {};
      state.isPaymentDone = {};
    },
    fetchPaymentData(state, action) {
      console.log("SubscriptionPlan ==>", action.payload);
      state.paymentData = action.payload;
      state.paymnetLoader = false;
      const errCount = localStorage.getItem("errorCount");
      if (action.payload?.success) {
        // reactPixelEvent("Payment Info", localStorage.getItem("PlanDetails"));
        // localStorage.setItem("currentStep", "4");
        // localStorage.setItem("skip", "1");
        // localStorage.removeItem("isOwnerVerified");
        // localStorage.setItem("errorCount", 0);
        state.errorFlagCount = 0;
        // localStorage.removeItem("ptIds");
      } else {
        localStorage.setItem("currentStep", "2"); //previous step 3
        localStorage.setItem("isOwnerVerified", "1");
        localStorage.setItem(
          "errorCount",
          (errCount ? parseInt(errCount) : 0) + 1
        );
        state.errorFlagCount = (errCount ? parseInt(errCount) : 0) + 1;
      }
    },
    getIsPaymentDone(state, action) {
      console.log("SubscriptionPlan==>>", action.payload);
      // state.isPaymentDone = {};
      state.paymnetLoader = true;
    },
    fetchIsPaymentDone(state, action) {
      console.log("SubscriptionPlan==>>", action.payload);
      state.isPaymentDone = action.payload;
      state.paymnetLoader = false;
    },
    getTotalPaymentDetails(state, action) {
      console.log("totalPaymentDetails==>>", action.payload);
      state.finalAmountPayload = action.payload?.data;
      state.creditCardInfo = {};
      state.paymentData = {};
      state.statesLoader = true;
      state.totalPaymentLoader = true;
      state.getTotalAmountLoader = true;
    },
    fetchTotalPaymentDetails(state, action) {
      console.log("totalPaymentDetails ==>", action.payload);
      state.totalPaymentDetails = action.payload;
      state.totalPayment = action.payload;
      state.statesLoader = false;
      state.totalPaymentLoader = false;
      state.getTotalAmountLoader = false;
    },
    addBeneficiaryDetails(state, action) {
      state.beneficiaryLoader = true;
    },
    setBeneficiaryDetails(state, action) {
      state.beneficiaryLoader = false;
      console.log("beneficiary data==>", action.payload);
      const errCount = localStorage.getItem("errorCount");
      if (action.payload?.success) {
        state.beneficiaryList = [...state.beneficiaryList, action.payload.data];
        state.beneficiaryError = "";
        localStorage.setItem("completed", "1");
        localStorage.setItem("skip", "0");
        localStorage.setItem("errorCount", 0);
        state.errorFlagCount = 0;
      } else {
        state.beneficiaryError = action.payload?.data?.message;

        localStorage.setItem("completed", "0");
        localStorage.setItem(
          "errorCount",
          (errCount ? parseInt(errCount) : 0) + 1
        );
        state.errorFlagCount = (errCount ? parseInt(errCount) : 0) + 1;
      }

      localStorage.setItem("currentStep", "7");
    },
    fetchBeneficiaryList(state, action) {
      state.beneficiaryLoader = true;
      localStorage.setItem("skip", "0");
    },
    setBeneficiaryList(state, action) {
      state.beneficiaryList = action.payload.data;
      state.beneficiaryLoader = false;
    },
    skipBeneficiaryStep(state, action) {
      console.log("skip register-face==>>", action.payload);
    },
    skipBeneficiaryStepData(state, action) {
      console.log("skip register-face response==>>", action.payload);
    },
    sendSignUpMail(state, action) {
      state.sendMailLoader = true;
    },
    setSendMailDetails(state, action) {
      state.sendMailDetails = action.payload;
      reactPixelEvent("Complete Registration", {
        email: user_signup_details()?.email,
      });
      state.sendMailLoader = false;
      // localStorage.clear();
      // localStorage.setItem("preference", true);
    },
    getResumeProcessEmail(state, action) {
      console.log("getResumeProcessEmail==>", action.payload);
      state.resumeProcessLoader = true;
    },
    fetchResumeProcessData(state, action) {
      console.log("fetch ResumeProcessEmail ==>", action.payload);
      state.resumeProcessLoader = false;
      state.resumeProcessData = action.payload;
    },
    clearResumeProcessData(state, action) {
      state.resumeProcessLoader = false;
      state.resumeProcessData = {};
      state.resumeProcessResponse = {};
      state.emailOTPVerificationResponse = {};
      state.resendEmailOtpResponse = {};
    },
    userSecurityPin(state, action) {
      state.propertyIds = [];
      state.properties = [];
      state.sendMailLoader = true;
      state.finalAmountPayload = {};
    },
    signBase64Value(state, action) {
      console.log("base 64 image value ==>", action.payload);
      state.signBase64 = action.payload;
    },
    clearSignBase64Value(state, action) {
      state.signBase64 = "";
    },
    fetchUserSecurityPin(state, action) {
      state.userSecurityPin = action.payload;
      state.sendMailLoader = false;
      const errCount = localStorage.getItem("errorCount");
      if (action.payload?.success) {
        localStorage.setItem("currentStep", "5");
        localStorage.setItem("skip", "1");
        localStorage.setItem("errorCount", 0);
        state.errorFlagCount = 0;
      } else {
        localStorage.setItem("currentStep", "4");
        localStorage.setItem("skip", "0");
        localStorage.setItem(
          "errorCount",
          (errCount ? parseInt(errCount) : 0) + 1
        );
        state.errorFlagCount = (errCount ? parseInt(errCount) : 0) + 1;
      }
    },
    userLoginData(state, action) {
      console.log("login data==>>", action.payload);
      state.userCredentials = action.payload?.data;
      state.userLoginLoader = true;
    },
    fetchUserLoggedInData(state, action) {
      console.log("In state slice...", action.payload);
      state.userLoginLoader = false;
      state.userSignInDetails = action.payload;
      if (action.payload?.success) {
        if (action.payload.data?.role?.includes(ROLES.customer)) {
          reactPixelEvent("SignIn", { email: state.userCredentials?.email });
        }
      }

      localStorage.setItem("authToken", action.payload.data?.AccessToken);
      localStorage.setItem("authorised", action.payload.success);

      if (action.payload?.data?.role) {
        localStorage.setItem(
          "roles",
          encryptPayload(action.payload?.data?.role)
        );
      } else {
        localStorage.setItem(
          "roles",
          encryptPayload(action?.payload?.data?.user?.user_type?.[0])
        );
      }
      // localStorage.setItem("authToken", action.payload.data?.AccessToken);
    },
    clearuserLoginData(state, action) {
      console.log("login data==>>", action.payload);
      state.userCredentials = {};
    },
    getInvitationDetails(state, action) {
      console.log("getInvitationDetails data==>>", action.payload);
      state.getInvitationDetails = {};
      state.userLoginLoader = true;
    },
    fetchInvitationDetails(state, action) {
      console.log("getInvitationDetails data==>>", action.payload);
      state.getInvitationDetails = action.payload;
      state.userLoginLoader = false;
    },
    checkreCAPTCHA(state, action) {
      state.checkReCAPTCHALoader = true;
    },
    setreCAPTCHA(state, action) {
      state.checkReCAPTCHADetails = action?.payload;
      state.checkReCAPTCHALoader = false;
    },
    setIsFromIosDevice(state, action) {
      state.isFromIosDevice = action?.payload;
    },
    getRiskReportDetails(state, action) {
      console.log("getRiskReportDetails data==>>", action.payload);
      state.userLoginLoader = true;
    },
    fetchRiskReportDetails(state, action) {
      console.log("getRiskReportDetails data==>>", action.payload);
      state.getRiskReportDetails = action.payload;
      state.userLoginLoader = false;
    },
  },
});
export const userSigupAction = signupUser.actions;
export default signupUser.reducer;
